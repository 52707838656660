import React, { useRef, useState, useEffect } from "react";
import "../utils/auth.scss";
import image1 from "../utils/img/image-1.png";
import image2 from "../utils/img/image-2.png";
import itemLogo from "../../bin/pngegg.png"
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authAPI/authSlice'
import { useLoginMutation } from './authAPI/authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import { FaRegEnvelope } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";

export default function SignIn() {

    useTitle('Employee Login')

    const [email, setMail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()


    const handleSubmit = async (e) => {
        e.preventDefault()
        setPersist(true)
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            toast.success('Success! Redirecting..')
            setTimeout(() => {
                setUsername('')
                setPassword('')
                setMail('')
                navigate('/dash')
            }, 600);
        } catch (err) {
            if (!err.status) {
                toast.error('No Server Response');
            } else if (err.status === 400) {
                toast.error('Missing Username or Password');
            } else if (err.status === 401) {
                toast.error('Unauthorized');
            } else if(err.status === 429){
                toast.error('Too many login attempts, try again later.');
            } else {
                toast.error('Invalid email or password');
            }
        }
    }


    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <div>
            <Toaster
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                }}
            />

            <div className="wrapper">
                <div className="inner">
                    <img src={image1} alt="" className="image-1" />
                    <form className="form-main" action="">
                        <div>
                            <div className="logoHere-auth">
                                <img style={{ width: "70px", height: "70px" }} src={itemLogo} />
                                <h3>TOP ANNOTATE</h3>
                            </div>
                            <div className="ttl-intro">
                                <h4>Continue Your Journey With TOP ANNOTATE,</h4>
                                <p>Log into Your Business Account.</p>
                                <p>Want to create an Account? <span style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={() => { navigate('/signup') }}>SignUp</span></p>
                            </div>
                        </div>
                        <div className="form-holder">
                            <span className="lnr lnr-envelope"><FaRegEnvelope /></span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Mail"
                                value={email}
                                onChange={(event) => {
                                    setMail(event.target.value)
                                    setUsername(event.target.value)
                                }}
                                required
                            />
                        </div>
                        <div className="form-holder">
                            <span className="lnr lnr-lock"><MdLockOutline style={{width: '30px',height: '18px'}} /></span>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={password}
                                onChange={(event) => (setPassword(event.target.value))}
                            />
                        </div>
                        <button onClick={handleSubmit} className="log-main-btn">
                            <span>Log In</span>
                        </button>
                    </form>
                    <img src={image2} alt="" className="image-2" />
                </div>
            </div>
        </div>
    )

    return content


}
