import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { useWithdrawalMutation } from '../features/users/usersApiSlice';
import toast, { Toaster } from "react-hot-toast";

function Withdrow({activation, contact, setPay}) {
    const [act, setAct] = useState(false);
    const [amount, setAmount] = useState(false);

    const [withdrawal, {
        isLoading,
        isSuccess,
        isError,
        error
      }] = useWithdrawalMutation()

      useEffect(() => {
        if (isSuccess) {
          setAmount('')
          toast.success('Withdrawal Posted successfully')
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          setTimeout(() => {
            toast.success('Processing...')
          }, 600);
        }
      }, [isSuccess])

      useEffect(() => {
        if (isError) {
          if (error && error.status === 400) { // Check for 400 Bad Request
            if (error.data.message) {
              toast.error('Input Error: ' + error.data.message);
            } else {
              toast.error('Bad Request. Please check your input.'); // Generic 400 message 
            }
          } else if (error && error.status === 409) { // Check for 409 Conflict
            toast.error('Conflict: ' + error.data.message);
          } else {
            toast.error('Error: ' + (error.data.message || 'An unknown error occurred')); // Fallback
            console.log(error)
          }
        }
      }, [isError]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (activation === 'Active') {
            await withdrawal({ amount })
        } else {
            setAct(true)
        }

    };

  return (
    <div>
              <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />

    <form className="form-main-wallet" onSubmit={handleSubmit}>
        <div className="form-item-wallet">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <span><FaPhoneAlt /></span>
            <input type="tel" id="phoneNumber" placeholder="07..." value={contact} disabled />
        </div>
        <div className="form-item-wallet">
            <label htmlFor="views">Amount:</label>
            <span><MdOutlineAttachMoney style={{ width: '23px', height: '23px' }} /></span>
            <input type="number" id="views" value={amount} style={{border : '1px solid #e6e6e6'}} onChange={(e) => setAmount(e.target.value)} />
        </div>
        {act ? <><p style={{color:'red'}}>Activate account to withdraw</p><p style={{color: 'green', padding: '10px 0'}} onClick={setPay}> Activate Now</p></> : ''}

        <button type="submit" className='submit-form-wallet'>{isLoading ? 'Loading ...' : 'WITHDRAW'}</button>
    </form>
</div>
);
}

export default Withdrow;
