import React, { useState } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { GoChevronRight } from "react-icons/go";
import { PiGameControllerBold } from "react-icons/pi";
import { PiWalletBold } from "react-icons/pi";
import { PiConfettiBold } from "react-icons/pi";
import { BsWhatsapp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MdAddTask } from "react-icons/md";

export default function Pannel(props) {
    const [open, setOpen] = useState('')

    const navigate = useNavigate()

    return (
        <div className="pan-content">
            <div onClick={() => {
                props.mobileScreen();
            }} className="sheet"></div>
            <ul className="pan-item-main">
                <li className='nav-item open'>
                    <div className="con-main">
                        <div className="move-con" onClick={() => props.mobileScreen()}>
                            <HiOutlineHome className="icon-p" />
                            <span className="menu-title" >
                                Dashboard
                            </span>
                        </div>
                        <GoChevronRight className="icon-r" />
                    </div>
                    <ul className='menu-content'>
                        <li className="is-shown" onClick={() => { navigate('/dash'); props.mobileScreen()}}>
                            <span>Profile</span>
                        </li>
                        <li className="is-shown" onClick={() => { navigate('wallet'); props.mobileScreen()}}>
                            <span>Wallet</span>
                        </li>
                        <li className="is-shown" onClick={() => { navigate('activity'); props.mobileScreen() }}>
                            <span>Activity</span>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="pan-item-main">
                <li className='nav-item open'>
                    <div onClick={() => {props.mobileScreen()}} className="con-main">
                        <div className="move-con">
                            <MdAddTask className="icon-p" />
                            <span className="menu-title" >
                                Tasks
                            </span>
                        </div>
                        <GoChevronRight className="icon-r" />
                    </div>
                    <ul className='menu-content'>
                        <li className="is-shown" onClick={() => { navigate('/dash/task'); props.mobileScreen() }}>
                            <span>Available Tasks</span>
                        </li>
                        <li className="is-shown" onClick={() => { navigate('/dash/task/completed'); props.mobileScreen() }} >
                            <span>Completed Tasks</span>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="pan-item-main">
                <li className={`nav-item `}>
                    <div className="con-main">
                        <div className="move-con" onClick={() => { navigate('/dash/whatsapp'); props.mobileScreen() }}>
                            <BsWhatsapp className="icon-p" />
                            <span className="menu-title" >
                                WhatsApp Center
                            </span>
                        </div>
                    </div>
                </li>
            </ul>

            {/* <ul className="pan-item-main">
                <li className={`nav-item `}>
                    <div className="con-main">
                        <div className="move-con">
                            <PiGameControllerBold className="icon-p" />
                            <span className="menu-title" >
                                Cash Games
                            </span>
                        </div>
                    </div>
                </li>
            </ul> */}
            <ul className="pan-item-main">
                <li className='nav-item open'>
                    <div onClick={() => {props.mobileScreen()}} className="con-main">
                        <div className="move-con">
                            <PiWalletBold className="icon-p" />
                            <span className="menu-title" >
                                E-wallet
                            </span>
                        </div>
                        <GoChevronRight className="icon-r" />
                    </div>
                    <ul className='menu-content'>
                        <li className="is-shown" onClick={() => { navigate('wallet'); props.mobileScreen() }}>
                            <span>Transfers</span>
                        </li>
                        <li className="is-shown" onClick={() => { navigate('redeem'); props.mobileScreen() }}>
                            <span>Redeem points</span>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="pan-item-main">
                <li className='nav-item open'>
                    <div onClick={() => {props.mobileScreen()}} className="con-main">
                        <div className="move-con">
                            <PiConfettiBold className="icon-p" />
                            <span className="menu-title" >
                                Reward Center
                            </span>
                        </div>
                        <GoChevronRight className="icon-r" />
                    </div>
                    <ul className='menu-content'>
                        <li className="is-shown" onClick={() => {navigate('wallet'); props.mobileScreen()}}>
                            <span>Cash Back</span>
                        </li>
                        <li className="is-shown" onClick={() => {props.mobileScreen()}}>
                            <span>Weekly Bonus</span>
                        </li>
                    </ul>
                </li>
            </ul>

        </div>
    );
}
