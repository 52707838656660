import React, { useState, useEffect } from "react";
import "./utils/auth.scss";
import image1 from "./utils/img/image-1.png";
import image2 from "./utils/img/image-2.png";
import itemLogo from "../bin/pngegg.png"
import { useAddNewUserMutation } from "../features/users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import useTitle from "../hooks/useTitle"
import toast, { Toaster } from "react-hot-toast";
import { validate } from "email-validator";
import { useSearchParams } from 'react-router-dom';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";


export default function SignUp() {

    useTitle('TOP ANNOTATE: New User')

    const [validUsername, setValidUsername] = useState(true)
    const [validPassword, setValidPassword] = useState(false)
    const [validPhone, setValidPone] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [username, setUserName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('254')
    const [password, setPassword] = useState('')
    const [invitedBy, setInvitedBy] = useState(null)
    const [email, setMail] = useState('')
    const [isDone, setIsDone] = useState(false)
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [usernameError, setUsernameError] = useState(null)
    const [gotError, setGotError] = useState(false)
    const [phoneError, setPhoneError] = useState(null)
    const [mesError, setMesError] = useState('')
    const [passError, setPassError] = useState(null)

    const [validConPassword, setValidConPassword] = useState(false)
    const [conPassword, setConPassword] = useState('')
    const [conPassError, setConPassError] = useState(null)

    const [loading, setLoading] = useState(false)

    const handleChange = () => {
        setAcceptedTerms(!acceptedTerms);
    };

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            setPassword('')
            setUserName('')
            setPhoneNumber('')
            toast.success('Registered successfully')
            setTimeout(() => {
                setLoading(false)
                setIsDone(prev => !prev)
            }, 600);

        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            if (error && error.status === 400) { // Check for 400 Bad Request
                if (error.data.error) {
                    toast.error('Input Error: ' + error.data.message);
                    setGotError(true)
                    setMesError(error.data.message)
                    setLoading(false)
                } else {
                    toast.error('Bad Request. Please check your input.'); // Generic 400 message
                    setGotError(true)
                    setMesError(error.data.message)
                    setLoading(false)
                }
            } else if (error && error.status === 409) { // Check for 409 Conflict
                toast.error('Conflict: ' + error.data.message);
                setGotError(true)
                setMesError(error.data.message)
                setLoading(false)
            } else {
                toast.error('Error: An unknown error occurred'); // Fallback
                setGotError(true)
                setMesError('An unknown error occurred')
                setLoading(false)
            }
        }
    }, [isError]);

    const canSave = [validConPassword, validUsername, acceptedTerms, validPassword, validEmail].every(Boolean) && !isLoading

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (canSave) {
            setLoading(true)
            await addNewUser({ email, password, username, phoneNumber, invitedBy })
        } else {
            toast.error('Error missing or invalid fields')
        }
    }

    useEffect(() => {
        if (username.length <= 1) {
            setValidUsername(false)
            return
        } else if (username.length > 20) {
            setUsernameError('Name Is too long short')
            setValidUsername(false)
            return
        } else if (username.length < 4) {
            setUsernameError('Name is too short')
            setValidUsername(false)
            return
        } else {
            setUsernameError(null)
            setValidUsername(true)
            return
        }
    }, [username])

    useEffect(() => {
        if (password.length <= 1) {
            setValidPassword(false)
            setPassError(null)
            return
        } else if (password.length <= 4) {
            setValidPassword(false)
            setPassError('password is too short')
            return
        } else {
            setValidPassword(true)
            setPassError(null)
            return
        }
    }, [password])

    useEffect(() => {
        if (conPassword.length <= 1) {
            setValidConPassword(false)
            setConPassError(null)
            return
        } else if (password !== conPassword) {
            setValidConPassword(false)
            setConPassError('password not match')
            return
        } else {
            setValidConPassword(true)
            setConPassError(null)
            return
        }
    }, [conPassword, password])

    useEffect(() => {
        if (!phoneNumber.startsWith('254')) {
            setPhoneError('Number must start with 254..')
            setValidPone(false)
            return
        } else if (phoneNumber.length !== 12) {
            setPhoneError(null)
            setValidPone(false)
            return
        } else {
            setPhoneError(null)
            setValidPone(true)
            return
        }
    }, [phoneNumber])

    useEffect(() => {
        setValidEmail(validate(email))
    }, [email])


    const [searchParams] = useSearchParams();
    const referrerUsername = searchParams.get('ref');

    useEffect(() => {

        if (referrerUsername) {
            setInvitedBy(referrerUsername);
        }

    }, [])

    return (
        <div>
            <Toaster
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                }}
            />
            <div className={`done-con ${isDone ? '' : 'hidden'}`}>
                <div className="comp-mess">
                    <h3> Registration successful!</h3>
                    <p> Click verification link sent to <span>{email}</span> to complete setup! </p>
                    <p>Also Check Spam!</p>
                    <IoCheckmarkDoneCircleOutline className="big-tick" />
                </div>
            </div>

            <div className={`loader-class ${loading ? '' : 'hidden'}`}>
                <div className="loader">
                </div>
            </div>

            <div className="wrapper">
                <div className="inner">
                    <img src={image1} alt="" className="image-1" />
                    <form className="form-main" action="">
                        <div>
                            <div className="logoHere-auth">
                                <img style={{ width: "70px", height: "70px" }} src={itemLogo} />
                                <h3>TOP ANNOTATE</h3>
                            </div>
                            <div className="ttl-intro">
                                <h4>Start Your Journey With TOP ANNOTATE,</h4>
                                <p>Enter your details to Create A New Account.</p>
                                <p>Already have an Account? <span style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={() => { navigate('/login') }}>LogIn</span></p>
                            </div>
                            {gotError ? <div style={{ color: 'red', padding: '0 0 10px 0px' }}>{mesError}</div> : ''}
                        </div>
                        {invitedBy ?
                            <div className="form-holder">
                                <label style={{ paddingBottom: '5px' }}>
                                    Invite code
                                </label>
                                <span className="lnr lnr-user"></span>
                                <input
                                    type="text"
                                    value={invitedBy}
                                    className="form-control"
                                    placeholder="Invited By"
                                    onChange={(event) => (setInvitedBy(event.target.value))}
                                    style={{ border: '1px solid #e6e6e6', background: '#39ff394a', borderRadius: '5px' }}
                                />
                            </div>
                            : ''}
                        <div className="form-holder">
                            <span className="lnr lnr-user"><FaRegCircleUser /></span>
                            <input
                                required
                                type="text"
                                className={`form-control ${validUsername ? '' : 'invalid'} `}
                                placeholder="Username"
                                value={username}
                                onChange={(event) => (setUserName(event.target.value))}
                            />
                            {usernameError ? <p style={{ color: 'red', fontSize: '12px', paddingTop: '2px' }}>{usernameError}</p> : ''}
                        </div>
                        <div className="form-holder">
                            <span className="lnr lnr-phone-handset"><FaPhone /></span>
                            <input
                                required
                                type="text"
                                className={`form-control ${validPhone ? '' : 'invalid'} `}
                                placeholder="Phone number 07..."
                                value={phoneNumber}
                                onChange={(event) => (setPhoneNumber(event.target.value))}
                            />
                            {phoneError ? <p style={{ color: 'red', fontSize: '12px', paddingTop: '2px' }}>{phoneError}</p> : ''}
                        </div>
                        <div className="form-holder">
                            <span className="lnr lnr-envelope"><FaRegEnvelope /></span>
                            <input type="text" required className={`form-control ${validEmail ? '' : 'invalid'} `}
                                placeholder="Mail" value={email}
                                onChange={(event) => (setMail(event.target.value))}
                            />
                        </div>
                        <div className="form-holder">
                            <span className="lnr lnr-lock"><MdLockOutline style={{ width: '30px', height: '18px' }} /></span>
                            <input
                                required
                                type="password"
                                className={`form-control ${validPassword ? '' : 'invalid'} `}
                                placeholder="Password"
                                value={password}
                                onChange={(event) => (setPassword(event.target.value))}
                            />
                            {passError ? <p style={{ color: 'red', fontSize: '12px', paddingTop: '2px' }}>{passError}</p> : ''}

                        </div>
                        <div className="form-holder">
                            <span className="lnr lnr-lock"><MdLockOutline style={{ width: '30px', height: '18px' }} /></span>
                            <input
                                required
                                type="password"
                                className={`form-control ${validConPassword ? '' : 'invalid'} `}
                                placeholder="Confirm Password"
                                value={conPassword}
                                onChange={(event) => (setConPassword(event.target.value))}
                            />
                            {conPassError ? <p style={{ color: 'red', fontSize: '12px', paddingTop: '2px' }}>{conPassError}</p> : ''}

                        </div>
                        <div className="form-holder">
                            <label style={{ display: 'flex', gap: '10px' }}>
                                <input
                                    style={{ width: '10%' }}
                                    className="form-control"
                                    type="radio"
                                    checked={acceptedTerms}
                                    onChange={handleChange}
                                />
                                <p>I have read and agree to the <a href="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></p>
                            </label>
                        </div>
                        <button onClick={handleSubmit} className="log-main-btn">
                            <span>Register</span>
                        </button>
                    </form>
                    <img src={image2} alt="" className="image-2" />
                </div>
            </div>
        </div>
    );
}
