import React from "react";
import { useNavigate } from "react-router-dom";

function TaskList() {

  const hexId = () => {
    const randomValue = Math.floor(Math.random() * 0xFFFFF); // Range from 0x00000 to 0xFFFFF
    return randomValue.toString(16).padStart(5, '0'); // Convert to hex, pad with leading zeros if needed
  }

  const navigate = useNavigate();

  const TaskVid = () => {
    const curHex = hexId()
    const pay = [ 50, 10, 50, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 30, 30, 30, 30][Math.floor(Math.random() * 20)]
    return (
      <div className='task-item'>
        <p className="">{curHex}</p>
        <p style={{fontWeight: '700'}}>Video Rating and Review</p>
        <p className="cash">Ksh.{pay}</p>
        <button onClick={() => navigate(`video/${curHex}?ref=${pay}`)} className="cool">claim task</button>
      </div>
    )
  }


  const TaskPic = () => {
    const curHex = hexId()
    const pay = [80, 80, 100, 50, 10, 50, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 50, 10, 50, 10][Math.floor(Math.random() * 20)]
    return (
      <div className='task-item'>
        <p className="">{curHex}</p>
        <p style={{fontWeight: '700'}}> Image Identification</p>
        <p className="cash">Ksh.{pay}</p>
        <button onClick={() => navigate(`image/${curHex}?ref=${pay}`)} className="cool">claim task</button>
      </div>
    )
  }

  const aalTasks = Math.floor(Math.random() * (14 - 5 + 1)) + 6;

  return (
    <div className="task-con">
      {Array.from({ length: aalTasks }, (_, index) => {
        return (
          [<TaskVid key={index} />, <TaskPic key={index} />][Math.floor(Math.random() * 2)]
        )
      })}
    </div>
  );
}

export default TaskList;
