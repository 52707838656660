import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'; // Import Outlet

import React, { createContext, useEffect, useState } from "react";
import "../dash.scss";
import restImg from "../../bin/pngwing.com.png"

import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

//project import

import ReportCard from '../ReportCard';
import { gridSpacing } from '../config.js';

// assets
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MonetizationOnTwoTone from '@mui/icons-material/MonetizationOnTwoTone';
import DescriptionTwoTone from '@mui/icons-material/DescriptionTwoTone';
import ThumbUpAltTwoTone from '@mui/icons-material/ThumbUpAltTwoTone';
import CalendarTodayTwoTone from '@mui/icons-material/CalendarTodayTwoTone';
import useAuth from "../../hooks/useAuth.js";
import useTitle from "../../hooks/useTitle.js";
import { useGetUsersQuery } from "../../features/users/usersApiSlice.js";

function TaskLayout() {

  useTitle('TOP ANNOTATE: Task dash')


  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 190000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })


  const [lifeTime, setLifetime] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [showEarn, setShowEarn] = useState(false)
  const [working, setWorking] = useState(false)
  const [activation, setActivation] = useState('');


  const [searchParams] = useSearchParams();
  const referrerUsername = searchParams.get('ref');

  useEffect(() => {

    if (referrerUsername) {
      setWorking(true);
    } else {
      setWorking(false);
    }

  }, [referrerUsername])



  useEffect(() => {
    if (isSuccess) {

      const userDataArray = Object.values(users.entities);
      setLifetime(userDataArray[0].completedTasks.reduce((sum, task) => sum + task.amount, 0))
      setCompletedTasks((userDataArray[0].completedTasks.length))
      setTokenBalance(userDataArray[0].earningsBalance)
      setActivation((userDataArray[0].activationStatus ? 'Active' : 'Inactive'))
    }
  }, [isSuccess])

  const { username, email } = useAuth()

  const theme = useTheme();

  const navigate = useNavigate()

  return (
    <div className="dashcon">
      {working ? '' : <><div className="wel-ttl">
        <h1>
          Welcome to TOP ANNOTATE <span> TASKS </span>
        </h1>
      </div>
      <div className="welc-ban">
        <div className="img-con">
          <img src={restImg} />
        </div>
        <div className="text-con">
          <div className="text-one">
            <p>Welcome <span>{username}</span>!</p>
          </div>
          <div className="text-2">
            <p>
              This is TOP ANNOTATE work program.
            </p>
          </div>
        </div>
      </div>

      </>
      }
      <div className='task-tab'>
        <div className='intro-task'>
          <p className='main-intro'>Complete Task and Earn</p>
          <div style={{ display: 'flex', gap: '10px'}}>
          <p onClick={() => {
            setShowEarn(prev => !prev)
          }} className='toggle'>View Earnings</p>
          <p onClick={() => {
            if (showEarn == true) {
              setShowEarn(prev => !prev)
            }
            navigate('/dash/task/completed')
          }} className='toggle'>View recent task</p>
          </div>
        </div>
        <div className={`${showEarn ? '' : 'hidden'}`}>
          <Grid container spacing={gridSpacing} className="dashhere">
            <Grid item xs={12} className="fst-items">
              <Grid container spacing={gridSpacing}>
                <Grid item lg={3} sm={6} xs={12}>
                  <ReportCard
                    primary={lifeTime}
                    secondary="Tasks Earnings"
                    color={theme.palette.warning.main}
                    footerData="100% Pending review"
                    iconPrimary={MonetizationOnTwoTone}
                    iconFooter={TrendingUpIcon}
                  />
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <ReportCard
                    primary={completedTasks}
                    secondary="Completed Task"
                    color={theme.palette.error.main}
                    footerData={`${Math.floor(Math.random() * (80 - 20 + 1)) + 30}% task performance`}
                    iconPrimary={CalendarTodayTwoTone}
                    iconFooter={TrendingDownIcon}
                  />
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <ReportCard
                    primary={tokenBalance}
                    secondary="Processed Balance"
                    color={theme.palette.success.main}
                    footerData="100% approved task"
                    iconPrimary={DescriptionTwoTone}
                    iconFooter={TrendingUpIcon}
                  />
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <ReportCard
                    primary={activation}
                    secondary="status"
                    color={theme.palette.primary.main}
                    footerData="Status To process tasks"
                    iconPrimary={ThumbUpAltTwoTone}
                    iconFooter={TrendingUpIcon}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default TaskLayout;
