import React, { useState, useEffect } from "react";
import "./Feedback.scss"; // Import your CSS file
import { FaStar } from "react-icons/fa";
import { useRegisterTaskMutation } from "../../../features/users/usersApiSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function Feedback() {

  const [amount, setAmount] = useState(0)
  const [comment, setComment] = useState()

  const navigate = useNavigate();

  const [registerTask, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useRegisterTaskMutation()

  const { taskId } = useParams()

  useEffect(() => {
    if (isSuccess) {
      setAmount('')
      toast.success('Task Posted successfully')
      setTimeout(() => {
        navigate('/dash/task', { replace: true })
        window.location.reload();
      }, 2000);
      setTimeout(() => {
        toast.success('Redirecting...')
      }, 1000);
    }
  }, [isSuccess, navigate])

  useEffect(() => {
    if (isError) {
      if (error && error.status === 400) { // Check for 400 Bad Request
        if (error.data.error) {
          toast.error('Input Error: ' + error.data.error);
        } else {
          toast.error('Bad Request. Please check your input.'); // Generic 400 message 
        }
      } else if (error && error.status === 409) { // Check for 409 Conflict
        toast.error('Conflict: ' + error.data.error);
      } else {
        toast.error('Error: ' + (error.data.message || 'An unknown error occurred')); // Fallback
        console.log(error)
      }
    }
  }, [isError]);


  const [rating, setRating] = useState(0);
  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const [progress, setProgress] = useState(0);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 2.5
      );
    }, 100);

    if (progress === 100) {
      setIsButtonEnabled(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [progress]);

  const canSave = [taskId, amount, comment].every(Boolean) && !isLoading

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (canSave) {
      await registerTask({ taskId, amount, comment })
    } else {
      toast.error('Error missing or invalid fields')
    }
  }

  const [searchParams] = useSearchParams();
  const referrerUsername = searchParams.get('ref');

  useEffect(() => {
    if (referrerUsername) {
      // Convert the referrerUsername (string) to a number
      const amountValue = parseInt(referrerUsername, 10); // Use base 10 for decimal conversion

      // Check if the conversion was successful (parseInt returns NaN if not a number)
      if (!isNaN(amountValue)) {
        setAmount(amountValue);
      } else {
        setAmount(null); // Or set a default value if conversion fails
      }
    } else {
      setAmount(null);
    }
  }, [referrerUsername]);

  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />

      <div className="feedback-container">
        <p>Rate The Media</p>
        <div className="rating-container">
          {[...Array(5)].map((_, index) => (
            <FaStar
              onClick={() => handleStarClick(index)}
              key={index}
              className={`stars ${index < rating ? "active" : ""}`}
            />
          ))}
        </div>
      </div>
      <div className="comment-area">
        <p>Describe the media content.</p>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          name="text"
          class="validate[required,length[6,300]] feedback-input"
          id="comment"
          placeholder="Comment"
        />
      </div>
      <div className="progress-bar-con">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={!isButtonEnabled}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default Feedback;
