import React from 'react';
import "./reftable.scss"

function ReferralTable({ referrals }) {
  return (
    <div className="referral-table">
      <h3>Your Referral Track</h3>
      <div className="table-header">
        <div className="header-cell">Ref Code</div>
        <div className="header-cell">Status</div>
      </div>
      {referrals.length > 0 ? (
        <> 
          {referrals.map((referral, index) => (
            <div className="table-row" key={index}>
              <div className="row-cell">{referral.referred}</div>
              <div className="row-cell">{referral.status}</div>
            </div>
          ))}
        </>
      ) : (
        <p>No referrals found.</p>
      )}
    </div>
  );
}

export default ReferralTable;
