import React, { useEffect, useState } from "react";
import "./dash.scss";
import restImg from "../bin/email.svg"

import { useTheme } from '@mui/material/styles';
import { Grid, Card, CardHeader, CardContent, Typography, Divider, LinearProgress } from '@mui/material';

//project import
import SalesLineCard from './SalesLineCard';
import SalesLineCardData from './chart/sale-chart-1';

import RevenuChartCard from './RevenuChartCard';
import RevenuChartCardData from './chart/revenu-chart';

import toast, { Toaster } from "react-hot-toast";

import ReportCard from './ReportCard';
import ReferralTable from '../components/ReferralTable.js';
import { gridSpacing } from './config.js';
import { FaCopy } from "react-icons/fa";

// assets
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MonetizationOnTwoTone from '@mui/icons-material/MonetizationOnTwoTone';
import DescriptionTwoTone from '@mui/icons-material/DescriptionTwoTone';
import ThumbUpAltTwoTone from '@mui/icons-material/ThumbUpAltTwoTone';
import CalendarTodayTwoTone from '@mui/icons-material/CalendarTodayTwoTone';
import useAuth from "../hooks/useAuth.js";
import useTitle from "../hooks/useTitle.js";
import { useGetUsersQuery } from "../features/users/usersApiSlice.js";
import { useNavigate } from "react-router-dom";
import Nagging from "./nug/Nagging.js";
import ActivateQuery from "./nug/ActivateQuery.js";


function DashboardMain() {

  useTitle('TOP ANNOTATE: Users dash')

  const {
    data: users,
    isSuccess,
  } = useGetUsersQuery('usersList', {
    pollingInterval: 190000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })


  const [lifeTime, setLifetime] = useState(0);
  const [earningsBalance, setEarningsBalance] = useState(0);
  const [referral, setReferral] = useState(0);
  const [referralCode, setReferralCode] = useState(null);
  const [referralData, setReferralData] = useState([]);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [activation, setActivation] = useState('');
  const [nag, setNag] = useState(false);
  const [pasNow, setPasNow] = useState(false);
  const [contact, setContact] = useState(false);


  useEffect(() => {
    if (isSuccess) {

      const userDataArray = Object.values(users.entities);
      setLifetime(userDataArray[0].completedTasks.reduce((sum, task) => sum + task.amount, 0)
        + userDataArray[0].subscription.reduce((sum, task) => sum + task.amount, 0))
      setEarningsBalance(userDataArray[0].earningsBalance)
      setReferral(userDataArray[0].currentReferrals.length)
      setReferralData(userDataArray[0].currentReferrals)
      setContact(userDataArray[0].phoneNumber)
      setReferralCode(userDataArray[0].referralCode)
      setCompletedTasks((userDataArray[0].completedTasks.length + userDataArray[0].subscription.length))
      setActivation((userDataArray[0].activationStatus ? 'Active' : 'Inactive'))
    }
  }, [isSuccess])

  const { username, email } = useAuth()

  const setPay = () => {
    if (activation === 'Inactive') {
      setPasNow(prev => !prev)
    }
  }

  const theme = useTheme();

  const navigate = useNavigate()

  const reflink = `https://www.topannotater.space/signup?ref=${referralCode}`

  useEffect(() => {
    if (activation === 'Inactive') {
      setTimeout(() => {
        setNag(true)
      }, 30000);
    }

  }, [])

  return (
    <div className="dashcon">
      {nag ? <Nagging setPay={setPay} /> : ''}
      {pasNow ? <ActivateQuery contact={contact} /> : ''}


      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />

      <div className="wel-ttl">
        <h1>
          Welcome to <span> TOP ANNOTATE </span>
        </h1>
      </div>
      <div className="welc-ban">
        <div className="img-con">
          <img src={restImg} />
        </div>
        <div className="text-con">
          <div className="text-one">
            <p>Welcome back <span>{username}</span>!</p>
          </div>
          <div className="text-2">
            <p>
              Want to be the first to know about TOP ANNOTATE
            </p>
          </div>
          {activation === 'Inactive' ? <p style={{ color: 'green', padding: '10px 0' }} onClick={setPay}> Activate Now</p> : ''}

        </div>
      </div>
      <div className="bunner-incite">
        <p>
        TOP ANNOTATE NEW AWARDS RESOLUTIONS BUY BEST ACTIVE - PACKAGES, ONLINE WRITTING, FOREX ,ADVERTISE RECEIVE 80 % CASH BACK ON EVERY DEPOSIT AND ITS CLAIMABLE ON WITHDRAWAL ( CASH TO BE RECEIVED IN YOUR M-PESA)
        </p>
        <div className="director-tabs">
          <div className="tabs" onClick={() => navigate('/dash/task')}>
            <p>TASK AND EARN</p>
          </div>
          <div className="tabs" onClick={() => navigate('/dash/whatsapp')}>
            <p>POST AND EARN</p>
          </div>
        </div>
      </div>
      <Grid container spacing={gridSpacing} className="dashhere">
        <Grid item xs={12} className="fst-items">
          <Grid container spacing={gridSpacing}>
            <Grid item onClick={() => { navigate('wallet') }} lg={3} sm={6} xs={12}>
              <ReportCard
                primary={lifeTime}
                secondary="Lifetime Earnings"
                color={theme.palette.warning.main}
                footerData="03% changes on profit"
                iconPrimary={MonetizationOnTwoTone}
                iconFooter={TrendingUpIcon}
              />
            </Grid>
            <Grid onClick={() => { navigate('/dash/task/completed') }} item lg={3} sm={6} xs={12}>
              <ReportCard
                primary={completedTasks}
                secondary=" All Completed Task"
                color={theme.palette.error.main}
                footerData={`${Math.floor(Math.random() * (120 - 30 + 1)) + 30} available tasks`}
                iconPrimary={CalendarTodayTwoTone}
                iconFooter={TrendingDownIcon}
              />
            </Grid>
            <Grid onClick={() => { navigate('wallet') }} item lg={3} sm={6} xs={12}>
              <ReportCard
                primary={earningsBalance}
                secondary="processed income"
                color={theme.palette.success.main}
                footerData={`${completedTasks} pending processing`}
                iconPrimary={DescriptionTwoTone}
                iconFooter={TrendingUpIcon}
              />
            </Grid>
            <Grid onClick={setPay} item lg={3} sm={6} xs={12}>
              <ReportCard
                primary={activation}
                secondary="Status"
                color={theme.palette.primary.main}
                footerData="Account profile state"
                iconPrimary={ThumbUpAltTwoTone}
                iconFooter={TrendingUpIcon}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="user-data">
          <div className="title-user">
            <h4>MY INFO</h4>
          </div>
          <div className="user-info-data">
            <div className="info-con">
              <p>
                USERNAME
              </p>
              <input disabled value={username} />
            </div >
            <div className="info-con">
              <p>
                CONTACT
              </p>
              <input disabled value={email} />
            </div>
          </div>
          <div className="referral-con">
            <p className="ref-ttl">Unique referral Link</p>
            <div className="ref-act">
              <input disabled value={reflink} />
              <p
                onClick={() => {
                  console.log('here')
                  navigator.clipboard.writeText(reflink)
                    .then(() => {
                      toast.success('Copied!')
                    })
                    .catch(err => {
                      toast.success("Couldn't Copy")
                    });
                }}
              ><FaCopy style={{ height: '50%', width: '100%', color: 'white' }} /></p>
            </div>
          </div>
        </div>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={8} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <SalesLineCard
                        chartData={SalesLineCardData}
                        title="My team earning"
                        percentage="3%"
                        icon={<TrendingDownIcon />}
                        footerData={[
                          {
                            value: referralData.filter(referral => referral.status === 'active').length * 100,
                            label: 'Referral Revenue'
                          },
                          {
                            value: referral,
                            label: 'Total referrals'
                          }
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RevenuChartCard chartData={RevenuChartCardData} />
                </Grid>
              </Grid>
            </Grid>
            <ReferralTable referrals={referralData} />
            <Grid item lg={4} xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Typography component="div" className="card-header">
                      Traffic Sources
                    </Typography>
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                          <Typography variant="body2">Direct</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" align="right">
                            80%
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LinearProgress variant="determinate" aria-label="direct" value={80} color="primary" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                          <Typography variant="body2">Social</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" align="right">
                            50%
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LinearProgress variant="determinate" aria-label="Social" value={50} color="secondary" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                          <Typography variant="body2">Referral</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" align="right">
                            20%
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LinearProgress variant="determinate" aria-label="Referral" value={20} color="primary" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                          <Typography variant="body2">Bounce</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" align="right">
                            60%
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LinearProgress variant="determinate" aria-label="Bounce" value={60} color="secondary" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                          <Typography variant="body2">Internet</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" align="right">
                            40%
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LinearProgress variant="determinate" aria-label="Internet" value={40} color="primary" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}

export default DashboardMain;
