import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import '../auth/utils/auth.scss'

function VerifyEmail() {

    const [searchParams] = useSearchParams();
    const [token, setToken] = useState(null); // Store token in state
    const tokenid = searchParams.get('token');

    useEffect(() => {
        if (tokenid) {
            setToken(tokenid)
        } 
    }, [tokenid]);


    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const getOut = () => {
        setTimeout(() => {
            navigate('/login')
        }, 2000);
    }

    useEffect(() => {
        const verifyEmail = async () => {
            setIsLoading(true)
            try {
                if (token) { // Only proceed if token is not undefined
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}api/verify-email/${token}`
                    );

                    if (response.status === 200) {
                        toast.success('Email verified successfully!');
                        setTimeout(() => {
                            navigate('/login'); // Redirect to login after 2 seconds
                        }, 2000);
                        setIsLoading(false);
                    } else {
                        toast.error('Invalid or expired token.');
                        getOut()
                        setIsLoading(false);
                    }
                } else {
                    getOut()
                    setIsLoading(false);
                }
            } catch (error) {
                setError(error);
                toast.error('Error verifying email.');
                getOut()
                setIsLoading(false);
            }
        };

        verifyEmail();
    }, [token, navigate]); // Depend on the state-managed token

    return (
        <>
            <Toaster
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                }}
            />

            {isLoading ? (
            <div className={`loader-class`}>
            <div className="loader">
            </div>
        </div>
        ) : error ? (<div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', paddingTop: '300px'}}>
                <p>something went wrong</p>
                <p>Link may be invalid or You are already Verified</p>
                <button style={{padding: "20px", color:'blue', borderRadius: '5px'}}>login</button>
                </div>
            ) : ''}
        </>
    );
}

export default VerifyEmail;
