import React, { useRef, useState, useEffect } from "react";
import { IoMdCodeDownload } from "react-icons/io";
import monday from './monday.jpeg';
import tuesday from './tuesday.png';
import wednesday from './wednesday.jpg';
import thursday from './thursday.jpg';
import friday from './friday.jpg';
import saturday from './saturday.jpg'
import sunday from './sunday.jpg';
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { useSubmitTaskMutation } from "../../../features/users/usersApiSlice";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const images = [sunday, monday, tuesday, wednesday, thursday, friday, saturday];

function WhatsappImage() {

    const navigate = useNavigate();

    const [submitTask, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSubmitTaskMutation()

    useEffect(() => {
        if (isSuccess) {
            setPhoneNumber('');
            setViews('');
            setFile(null);
            setError(null);
            toast.success('Posted successfully');
            setTimeout(() => {
                navigate('/dash/whatsapp', { replace: true })
                window.location.reload();
            }, 2000);
            setTimeout(() => {
                toast.success('Redirecting...')
            }, 1000);
        }
    }, [isSuccess, navigate])

    useEffect(() => {
        if (isError) {
            if (error && error.status === 400) { // Check for 400 Bad Request
                if (error.data.message) {
                    toast.error('Input Error: ' + error.data.message);
                } else {
                    toast.error('Bad Request. Please check your input.'); // Generic 400 message 
                }
            } else if (error && error.status === 409) { // Check for 409 Conflict
                toast.error('Conflict: ' + error.data.error);
            } else {
                toast.error('Error: ' + (error.data.message || 'An unknown error occurred')); // Fallback
                console.log(error)
            }
        }
    }, [isError]);

    const currentDayIndex = new Date().getDay(); // 0 for Sunday, 1 for Monday, etc.
    const todaysImage = images[currentDayIndex];
    const today = new Date().toISOString().slice(0, 10);
    const imageRef = useRef(null);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = todaysImage;
        link.download = `image_for_${new Date().toLocaleString('en-US', { weekday: 'long' })}.jpg`; // Set filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [phoneNumber, setPhoneNumber] = useState('');
    const [views, setViews] = useState('');
    const [file, setFile] = useState(null);
    const [errors, setError] = useState(null);

    const [submitApp, setSubmitApp] = useState(false)

    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        if (!phoneNumber || !views || !file) {
            setError('All fields are required.');
            return;
        }

        if (!allowedFileTypes.includes(file.type)) {
            setError('Invalid file type. Please upload an image (JPEG, PNG, GIF) or a PDF.');
            return;
        }

        // ... (Handle form submission and data processing here)
        await submitTask({ phoneNumber, views })
    };

    return (
        <>
            <Toaster
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                }}
            />

            <div className={`what-sub-con ${submitApp ? '' : 'hidden'}`}>
                <div className="what-con">
                    <AiOutlineFullscreenExit onClick={() => setSubmitApp(prev => !prev)} className="leave" />
                    <form className="form-main" onSubmit={handleSubmit}>
                        <div className="form-item">
                            <label htmlFor="phoneNumber">Phone Number:</label>
                            <span><FaPhoneAlt /></span>
                            <input type="tel" id="phoneNumber" placeholder="07..." value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>
                        <div className="form-item">
                            <label htmlFor="views">Views:</label>
                            <span><FaRegEye style={{ width: '17px', height: '17px' }} /></span>
                            <input type="number" id="views" value={views} onChange={(e) => setViews(e.target.value)} />
                        </div>
                        <div className="form-item">
                            <label htmlFor="file">Media File (Image or PDF):</label>
                            <input type="file" id="file" accept={allowedFileTypes.join(',')} onChange={(e) => setFile(e.target.files[0])} />
                        </div>

                        {errors && <p style={{ color: 'red' }}>{errors}</p>}

                        <button type="submit" className='submit-form'>Submit</button>
                    </form>
                </div>
            </div>
            <div className="intro-top">
                <div className="intro-sub">
                    <p className="date-tag">{today}</p>
                    <p className="button-tag" onClick={handleDownload} >Download <IoMdCodeDownload style={{ height: '30px', width: '30px' }} /></p>
                </div>
                <div className="image-con">
                    <img ref={imageRef} src={todaysImage} alt={`Image for ${new Date().toLocaleString('en-US', { weekday: 'long' })}`} style={{ maxWidth: '100%', height: '100%' }} />
                </div>
                <div className="submit-tag">
                    <p onClick={() => setSubmitApp(prev => !prev)}>
                        Submit Screenshot
                    </p>
                </div>
            </div>
        </>
    );
}

export default WhatsappImage;
