import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../auth/accessAuth/authAPI/authSlice"
import { jwtDecode } from 'jwt-decode'

const useAuth = () => {

    let email = ""

    const token = useSelector(selectCurrentToken)

    if (token) {
        const decoded = jwtDecode(token)
        const { username, email } = decoded.UserInfo

        return { username, email }
    }

    return { username: '', email }
}
export default useAuth