import React, { useState } from "react";
import './nag.scss'
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineExitToApp } from "react-icons/md";
import { FaFreeCodeCamp } from "react-icons/fa6";
import { RiBillLine } from "react-icons/ri";
import { BsSend } from "react-icons/bs";

function ActivateQuery({contact}) {
    const [visible, setVisible] = useState(true)
    const [choice, setChoice] = useState(true)
    const [choice1, setChoice1] = useState(true)
    const [choice2, setChoice2] = useState(true)

    const handleSubmit = async (e) => {
        return
    };

    return (
        <div className={`act-main ${visible ? '' : 'hidden'}`}>
            <div className="nag-container">
                <MdOutlineExitToApp onClick={() => { setVisible(prev => !prev) }} className="exit" />
                <div className="green-alert">
                    <p>Pick an Activation Option.</p>
                    <div className="green-alert-con">
                        <div onClick={() => { setChoice(true) }} className={`alert-option ${choice ? 'alert-active' : ''}`}>
                            <FaFreeCodeCamp className="big-alert" />
                            <h1>Gold</h1>
                        </div>
                        <div onClick={() => { setChoice(false) }} className={`alert-option ${choice ? '' : 'alert-active'}`}>
                            <FaFreeCodeCamp className="big-alert" />
                            <h1>Platinum</h1>
                        </div>
                    </div>
                </div>
                <div className={`${choice ? '' : 'hidden'}`} >
                    <div className={`message ${choice1 ? '' : 'hidden'}`}>
                        <h3>What you get with this</h3>
                        <p> <IoMdCheckmark className="ticker" /> Retention fee is 100% refunded after 21 days</p>
                        <p> <IoMdCheckmark className="ticker" /> Immediate withdrawal of earnings</p>
                        <p> <IoMdCheckmark className="ticker" /> Priority Processing of Task claimed</p>
                        <p> <IoMdCheckmark className="ticker" /> Referee Earns 70ksh</p>
                    </div>
                    <div className={`${choice1 ? 'hidden' : ''}`}>
                        <form className="form-main-wallet">
                            <div className="form-item-wallet">
                                <label htmlFor="views">Amount:</label>
                                <span><BsSend style={{ width: '23px', height: '23px' }} /></span>
                                <input type="number" id="views" value='250' disabled />
                            </div>
                            <div className="form-item-wallet">
                                <label htmlFor="phoneNumber">Till Number:</label>
                                <span><RiBillLine /></span>
                                <input type="tel" id="phoneNumber" value='4444652'  disabled/>
                            </div>
                            <p>Tip: Only use your number <strong>{contact}</strong> as registered to subscribe.</p>
                            <p>Tip: Entity name 'Top Anote'</p>
                            <button type="submit" onClick={() => handleSubmit()} className='submit-form-wallet'>VALIDATE</button>
                            <button style={{ background: '#a3a3a3' }} className='submit-form-wallet' onClick={(e)=>{e.preventDefault();setChoice1(true)}}>CANCEL</button>
                        </form>
                    </div>
                    <div className={`butmn ${choice1 ? '' : 'hidden'}`} onClick={()=>setChoice1(false)} >
                        <button>Activate Now(250)</button>
                    </div>

                </div>
                <div className={`${choice ? 'hidden' : ''}`}>
                    <div className={`message ${choice2 ? '' : 'hidden'}`}>
                        <h3>What you get with this</h3>
                        <p> <IoMdCheckmark className="ticker" /> Retention fee is 100% refunded after 21 days</p>
                        <p> <IoMdCheckmark className="ticker" /> Immediate withdrawal of earnings</p>
                        <p> <IoMdCheckmark className="ticker" /> Priority Processing of Task claimed</p>
                        <p> <IoMdCheckmark className="ticker" /> Referee Earns 50ksh</p>
                    </div>
                    <div className={`${choice2 ? 'hidden' : ''}`}>
                    <form className="form-main-wallet">
                            <div className="form-item-wallet">
                                <label htmlFor="views">Amount:</label>
                                <span><BsSend style={{ width: '23px', height: '23px' }} /></span>
                                <input type="number" id="views" value='150' disabled />
                            </div>
                            <div className="form-item-wallet">
                                <label htmlFor="phoneNumber">Till Number:</label>
                                <span><RiBillLine /></span>
                                <input type="tel" id="phoneNumber" value='4444652'  disabled/>
                            </div>
                            <p>Tip: Only use your number <strong>{contact}</strong> as registered to subscribe.</p>
                            <p>Tip: Entity name 'Top Anote'</p>
                            <button type="submit" onClick={() => handleSubmit()} className='submit-form-wallet'>VALIDATE</button>
                            <button style={{ background: '#a3a3a3' }} className='submit-form-wallet' onClick={(e)=>{e.preventDefault();setChoice2(true)}}>CANCEL</button>
                        </form>
                    </div>

                    <div className={`butmn ${choice2 ? '' : 'hidden'}`} onClick={()=>setChoice2(false)} >
                        <button>Activate Now(150)</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ActivateQuery;
