import React, { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../features/users/usersApiSlice.js";

function CompletedTasks() {
  const [completedTasks, setCompletedTasks] = useState(0);


  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 190000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {
    if (isSuccess) {

      const userDataArray = Object.values(users.entities);
      setCompletedTasks((userDataArray[0].completedTasks))
    }
  }, [isSuccess])

  return (
    <div className="tasks-con">
      <h2 className="title-task">Completed Tasks</h2>
      {completedTasks.length > 0 ? (
        <ul className="item-con">
          <li className="ttl-item-single"> {/* Assign a unique key to each list item */}
            <p className="single-p"><span>Task ID</span></p>
            <p className="single-p"><span>Ref ID</span></p>
            <p className="single-p"><span>pay</span></p>
            <p className="single-p"><span>Status</span></p>
          </li>

          {/* Use a list to display multiple tasks */}
          {completedTasks.map((task, index) => (
            <li key={index} className="item-single"> {/* Assign a unique key to each list item */}
              <p className="single-p"> {task.taskId}</p>
              <p className="single-p"> {task._id.slice(0, 6)}</p>
              <p className="single-p"> {task.amount}</p>
              <p className={`single-p ${task.status === 'in review' ? "pend" : task.status === 'rejected' ? 'rejected' : 'approved'}`}>{task.status}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No completed tasks yet.</p>
      )}
    </div>
  );
}

export default CompletedTasks;
