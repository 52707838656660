import React, { useEffect, useState } from "react";
import "./dash.scss";
import useAuth from "../hooks/useAuth.js";
import useTitle from "../hooks/useTitle.js";
import { useGetUsersQuery } from "../features/users/usersApiSlice.js";
import restImg from "../bin/email.svg"
import Nagging from "./nug/Nagging.js";
import ActivateQuery from "./nug/ActivateQuery.js";


function Activity() {


    useTitle('TOP ANNOTATE: Users dash')

    const { username, email } = useAuth()

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 190000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const setPay = () => {
        if(activation === 'Inactive'){
          setPasNow(prev => !prev)
        }
      }


    const [completedTasks, setCompletedTasks] = useState(0);
    const [completedView, setCompletedView] = useState('');
    const [transactionData, setTransactionData] = useState(0);
    const [activation, setActivation] = useState('');
    const [nag, setNag] = useState(false);
    const [pasNow, setPasNow] = useState(false);

  
    useEffect(() => {
        if (isSuccess) {

            const userDataArray = Object.values(users.entities);
            setCompletedTasks(userDataArray[0].completedTasks)
            setCompletedView(userDataArray[0].subscription)
            setActivation((userDataArray[0].activationStatus ? 'Active' : 'Inactive'))
            setTransactionData(userDataArray[0].transactions)
        }
    }, [isSuccess])

    useEffect(()=>{
        if (activation === 'Inactive') {
          setTimeout(() => {
            setNag(true)
          }, 30000);
        }
    
      },[activation])
    
    return (
        <div style={{margin: '16px 18px'}}>
                  {nag ? <Nagging setPay={setPay} /> : ''}
                  {pasNow ? <ActivateQuery /> : ''}


            <div className="welc-ban">
                <div className="img-con">
                    <img src={restImg} />
                </div>
                <div className="text-con">
                    <div className="text-one">
                        <p>Hey  <span>{username}</span> see what you've been up to!</p>
                    </div>
                    <div className="text-2">
                        <p>
                            See your work!
                        </p>
                    </div>
                    {activation === 'Inactive' ? <p style={{color: 'green', padding: '10px 0'}} onClick={setPay}> Activate Now</p> : ''}
                </div>
            </div>

            <div className='item-while'>
                <h3>
                    Transaction History
                </h3>
            </div>
            <div className="tasks-con">
                {transactionData.length > 0 ? (
                    <ul className="item-con">
                        <li className="ttl-item-single"> {/* Assign a unique key to each list item */}
                            <p className="single-p"><span>Date</span></p>
                            <p className="single-p"><span>phoneNumber</span></p>
                            <p className="single-p"><span>amount</span></p>
                            <p className="single-p"><span>Status</span></p>
                        </li>

                        {/* Use a list to display multiple tasks */}
                        {completedTasks.map((task, index) => (
                            <li key={index} className="item-single"> {/* Assign a unique key to each list item */}
                                <p className="single-p"> {task.Date}</p>
                                <p className="single-p"> {task.phoneNumber}</p>
                                <p className="single-p"> {task.amount}</p>
                                <p className={`single-p ${task.status === 'in review' ? "pend" : task.status === 'rejected' ? 'rejected' : ''}`}>{task.status}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No Pending Transactions yet.</p>
                )}
            </div>
            <div className="tasks-con">
            <h2 className="title-task">View Task History</h2>
                {completedTasks.length > 0 ? (
                    <ul className="item-con">
                        <li className="ttl-item-single"> {/* Assign a unique key to each list item */}
                            <p className="single-p"><span>Task ID</span></p>
                            <p className="single-p"><span>Ref ID</span></p>
                            <p className="single-p"><span>pay</span></p>
                            <p className="single-p"><span>Status</span></p>
                        </li>

                        {/* Use a list to display multiple tasks */}
                        {completedTasks.map((task, index) => (
                            <li key={index} className="item-single"> {/* Assign a unique key to each list item */}
                                <p className="single-p"> {task.taskId}</p>
                                <p className="single-p"> {task._id.slice(0, 6)}</p>
                                <p className="single-p"> {task.amount}</p>
                                <p className={`single-p ${task.status === 'in review' ? "pend" : task.status === 'rejected' ? 'rejected' : ''}`}>{task.status}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No completed tasks yet.</p>
                )}
            </div>
            <div className="tasks-con">
                <h2 className="title-task">Submitted Posts</h2>
                {completedView.length > 0 ? (
                    <ul className="item-con">
                        <li className="ttl-item-single"> {/* Assign a unique key to each list item */}
                            <p className="single-p"><span>Date</span></p>
                            <p className="single-p"><span>Ref ID</span></p>
                            <p className="single-p"><span>pay</span></p>
                            <p className="single-p"><span>number</span></p>
                            <p className="single-p"><span>Status</span></p>
                        </li>
                        {/* Use a list to display multiple tasks */}
                        {completedView.map((task, index) => (
                            <li key={index} className="item-single"> {/* Assign a unique key to each list item */}
                                <p className="single-p"> {task.date}</p>
                                <p className="single-p"> {task._id.slice(0, 6)}</p>
                                <p className="single-p"> {task.amount ? task.amount : 'incomplete'}</p>
                                <p className="single-p"> {task.phoneNumber ? task.phoneNumber : 'incomplete'}</p>
                                <p className={`single-p ${task.status === 'in review' ? "pend" : task.status === 'rejected' ? 'rejected' : ''}`}>{task.status}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No Uploaded views yet.</p>
                )}
            </div>


        </div>
    );
}

export default Activity;
