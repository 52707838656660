import React from "react";
import Feedback from "./comp/Feedback";

function TaskForm() {
  return (
  <div>
    <Feedback />
  </div>
);
}

export default TaskForm;
