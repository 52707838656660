import React, { useEffect, useState } from "react";
import "./dash.scss";
import restImg from "../bin/email.svg"

import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

//project import
import SalesLineCard from './SalesLineCard';
import SalesLineCardData from './chart/sale-chart-1';

import RevenuChartCard from './RevenuChartCard';
import RevenuChartCardData from './chart/revenu-chart';

import { Toaster } from "react-hot-toast";

import { gridSpacing } from './config.js';

// assets
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import useAuth from "../hooks/useAuth.js";
import useTitle from "../hooks/useTitle.js";
import { useGetUsersQuery } from "../features/users/usersApiSlice.js";
import ReferralTable from "./ReferralTable.js";


function Reedem() {

  useTitle('TOP ANNOTATE: Users dash')

  const {
    data: users,
    isSuccess,
  } = useGetUsersQuery('usersList', {
    pollingInterval: 190000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })


  const [referralData, setReferralData] = useState([]);
  const [referral, setReferral] = useState(0);

  useEffect(() => {
    if (isSuccess) {

      const userDataArray = Object.values(users.entities);
      setReferralData(userDataArray[0].currentReferrals)
      setReferral(userDataArray[0].currentReferrals.length)
    }
  }, [isSuccess])

  const { username } = useAuth()


  return (
    <div className="dashcon">
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />

      <div className="wel-ttl">
        <h1>
          Welcome to <span> TOP ANNOTATE </span>
        </h1>
      </div>
      <div className="welc-ban">
        <div className="img-con">
          <img src={restImg} />
        </div>
        <div className="text-con">
          <div className="text-one">
            <p>Welcome back <span>{username}</span>!</p>
          </div>
          <div className="text-2">
            <p>
              Get rewarded by TOP ANNOTATE
            </p>
          </div>
        </div>
      </div>

      <Grid container spacing={gridSpacing} className="dashhere">
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={8} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <SalesLineCard
                        chartData={SalesLineCardData}
                        title="My team earning"
                        percentage="3%"
                        icon={<TrendingDownIcon />}
                        footerData={[
                          {
                            value: referralData.filter(referral => referral.status === 'active').length * 100,
                            label: 'Referral Revenue'
                          },
                          {
                            value: referral,
                            label: 'Total referrals'
                          }
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RevenuChartCard chartData={RevenuChartCardData} />
                </Grid>
              </Grid>
            </Grid>
            <ReferralTable referrals={referralData} />
            <Grid item lg={4} xs={12}>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Reedem;
