import React, { useState } from "react";
import './nag.scss'
import { GrAlert } from "react-icons/gr";
import { MdOutlineExitToApp } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

function Nagging({setPay}) {
    const[visible, setVisible] = useState(true)

  return (
  <div className={`nag-main ${visible ? '' : 'hidden'}`}>
    <div className="nag-container">
        <MdOutlineExitToApp onClick={() => {setVisible(prev => !prev)}} className="exit" />
        <div className="red-alert">
            <div>
                <GrAlert className="big-alert"/>
                <h1>ALERT</h1>
            </div>
            <p>We have noticed Your account is not active.</p>
        </div>
        <div className="message">
            <h4>Why You should Activate your account</h4>
            <p> <IoMdCheckmark className="ticker"/> Retention fee is 100% refunded after 21 days</p>
            <p> <IoMdCheckmark className="ticker"/> Immediate withdrawal of earnings</p>
            <p> <IoMdCheckmark className="ticker"/> Referee Earns 100ksh</p>
            <p> <IoMdCheckmark className="ticker"/> Priority Processing of Task</p>
        </div>
        <div className="butmn">
            <button onClick={(e)=>{e.preventDefault(); setPay()}}>Activate Now</button>
        </div>
    </div>
  </div>
);
}

export default Nagging;
