import React, { useEffect, useState } from "react";
import "./dash.scss";
import restImg from "../bin/email.svg"

import { useTheme, styled } from '@mui/material/styles';
import { Grid } from '@mui/material';


import toast, { Toaster } from "react-hot-toast";

import ReportCard from './ReportCard';
import { gridSpacing } from './config.js';
import ActivateQuery from "./nug/ActivateQuery.js";


// assets
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MonetizationOnTwoTone from '@mui/icons-material/MonetizationOnTwoTone';
import DescriptionTwoTone from '@mui/icons-material/DescriptionTwoTone';
import ThumbUpAltTwoTone from '@mui/icons-material/ThumbUpAltTwoTone';
import CalendarTodayTwoTone from '@mui/icons-material/CalendarTodayTwoTone';
import useAuth from "../hooks/useAuth.js";
import useTitle from "../hooks/useTitle.js";
import { useGetUsersQuery } from "../features/users/usersApiSlice.js";
import { BsCoin } from "react-icons/bs";
import Nagging from "./nug/Nagging.js";
import Withdrow from "./Withdrow.js";


function Wallet() {

    useTitle('TOP ANNOTATE: Users dash')

    const {
        data: users,
        isSuccess,
    } = useGetUsersQuery('usersList', {
        pollingInterval: 190000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    const [earningsBalance, setEarningsBalance] = useState(0);
    const [referral, setReferral] = useState(0);
    const [completedTasks, setCompletedTasks] = useState(0);
    const [activation, setActivation] = useState('');
    const [tasks, setTasks] = useState(0);
    const [viewPay, setViewPay] = useState(0);
    const [transactionData, setTransactionData] = useState(0);
    const [nag, setNag] = useState(false);
    const [pasNow, setPasNow] = useState(false);
    const [contact, setContact] = useState(false);
    const [activationStatus, setActivationStatus] = useState(false);

    function daysAgo(dateString) {
        const inputDate = new Date(dateString);
        const today = new Date();
      
        // Calculate time difference in milliseconds
        const timeDifference = today.getTime() - inputDate.getTime();
      
        // Convert milliseconds to days
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
        return daysDifference;
      }

    useEffect(() => {
        if (isSuccess) {

            const userDataArray = Object.values(users.entities);
            setTasks(userDataArray[0].completedTasks.reduce((sum, task) => sum + task.amount, 0))
            setViewPay(userDataArray[0].subscription.reduce((sum, task) => sum + task.amount, 0))
            setEarningsBalance(userDataArray[0].earningsBalance)
            setReferral(userDataArray[0].currentReferrals.filter(referral => referral.status === 'active').length)
            setCompletedTasks((userDataArray[0].completedTasks.length + userDataArray[0].subscription.length))
            setActivation((userDataArray[0].activationStatus ? 'Active' : 'Inactive'))
            setContact((userDataArray[0].phoneNumber))
            setTransactionData(userDataArray[0].transactions)
            setActivationStatus(userDataArray[0].activationStatusData)
        }
    }, [isSuccess])

    const { username, email } = useAuth()

    const theme = useTheme();

    useEffect(() => {
        if (activation === 'Inactive') {
            setTimeout(() => {
                setNag(true)
            }, 30000);
        }

    }, [activation])

    const setPay = () => {
        if(activation === 'Inactive'){
          setPasNow(prev => !prev)
        }
      }
    

    return (
        <div className="dashcon">
            {nag ? <Nagging setPay={setPay}/> : ''}
            {pasNow ? <ActivateQuery contact={contact}/> : ''}
            <Toaster
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                }}
            />

            <div className="wel-ttl">
                <h1>
                    Welcome to <span> Wallet </span>
                </h1>
            </div>
            <div className="welc-ban">
                <div className="img-con">
                    <img src={restImg} />
                </div>
                <div className="text-con">
                    <div className="text-one">
                        <p>Hey  <span>{username}</span> see what you've earned!</p>
                    </div>
                    <div className="text-2">
                        <p>
                            Your time to reap your rewards
                        </p>
                    </div>
                    {activation === 'Inactive' ? <p style={{color: 'green', padding: '10px 0'}} onClick={setPay}> Activate Now</p> : ''}

                </div>
            </div>
            <div className="dialogue-con">
                <div className="dialogue-ttl">
                    <h4>{username}'s Wallet</h4>
                    <p onClick={setPay} className={`stat ${activation === 'Active' ? 'allow' : 'reject'}`} >{activation}</p>
                </div>
                <div className="icons-con">
                    <div className="single-icon single-icon1">
                        <div>
                            <h1>{earningsBalance}</h1>
                            <BsCoin style={{ width: '45px', height: '45px' }} />
                        </div>
                        <p>processed Earnings</p>
                    </div>
                    <Withdrow activation={activation} contact={contact} setPay={setPay} />
                    <div style={{background : `${activation === 'Active' ? activationStatus.packageName === 'Gold' ? 'linear-gradient(135deg, rgb(151, 129, 0), rgb(255, 217, 0))' : 'linear-gradient(135deg, #9c9c9c, #e5e4e2)' : 'linear-gradient(135deg, rgb(35 19 241), rgb(134 127 191))'}`}} className="single-icon single-icon3">
                        <div>
                            <h1>{activation === 'Active' ? activationStatus.packageName === 'Gold' ? 500 : 300 : 0}</h1>
                            <BsCoin style={{ width: '45px', height: '45px' }} />
                        </div>
                        <div>
                        <p>Refund amount</p>
                        <p>{activation === 'Active' ? activationStatus.packageName : ''}</p>
                        </div>
                    </div>

                    <div className="single-icon single-icon2">
                        <div>
                            <h1>{activation === 'Active' ? (21 - daysAgo(activationStatus.date)) >= 0 ? 21 - daysAgo(activationStatus.date) : 0 : 0} Days</h1>
                            <BsCoin style={{ width: '45px', height: '45px' }} />
                        </div>
                        <div><p>Days To Refund</p></div>
                        
                    </div>
                </div>
            </div>
            <div className="item-while">
                <h2>Here is Your Income Break Down</h2>
            </div>
            <Grid container spacing={gridSpacing} className="dashhere">
                <Grid item xs={12} className="fst-items">
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ReportCard
                                primary={tasks}
                                secondary="Task Earnings"
                                color={theme.palette.warning.main}
                                footerData="100% changes on profit"
                                iconPrimary={MonetizationOnTwoTone}
                                iconFooter={TrendingUpIcon}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ReportCard
                                primary={viewPay}
                                secondary="Views Earnings"
                                color={theme.palette.error.main}
                                footerData={`${Math.floor(Math.random() * (120 - 30 + 1)) + 30} available tasks`}
                                iconPrimary={CalendarTodayTwoTone}
                                iconFooter={TrendingDownIcon}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ReportCard
                                primary={earningsBalance}
                                secondary="processed income"
                                color={theme.palette.success.main}
                                footerData={`${completedTasks} pending processing`}
                                iconPrimary={DescriptionTwoTone}
                                iconFooter={TrendingUpIcon}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ReportCard
                                primary={activation}
                                secondary="Status"
                                color={theme.palette.primary.main}
                                footerData="Account profile state"
                                iconPrimary={ThumbUpAltTwoTone}
                                iconFooter={TrendingUpIcon}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ReportCard
                                primary={referral * 100}
                                secondary="Referral Earnings"
                                color={theme.palette.error.main}
                                footerData={'Earn When referee activates'}
                                iconPrimary={CalendarTodayTwoTone}
                                iconFooter={TrendingDownIcon}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <div className='item-while'>
                <h3>
                    Transaction History
                </h3>
            </div>
            <div className="tasks-con">
                {transactionData.length > 0 ? (
                    <ul className="item-con">
                        <li className="ttl-item-single"> {/* Assign a unique key to each list item */}
                            <p className="single-p"><span>amount</span></p>
                            <p className="single-p"><span>Status</span></p>
                        </li>

                        {/* Use a list to display multiple tasks */}
                        {transactionData.map((task, index) => (
                            <li key={index} className="item-single"> {/* Assign a unique key to each list item */}
                                <p className="single-p"> {task.amount}</p>
                                <p className={`single-p ${task.status === 'in review' ? "pend" : task.status === 'rejected' ? 'rejected' : ''}`}>{task.status}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No Pending Transactions.</p>
                )}
            </div>
        </div>
    );
}

export default Wallet;
