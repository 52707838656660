import { Outlet, useSearchParams } from 'react-router-dom'; // Import Outlet

import React, { createContext, useEffect, useState } from "react";
import "../dash.scss";
import restImg from "../../bin/pngwing.com.png"

import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

//project import

import ReportCard from '../ReportCard';
import { gridSpacing } from '../config.js';

// assets
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MonetizationOnTwoTone from '@mui/icons-material/MonetizationOnTwoTone';
import DescriptionTwoTone from '@mui/icons-material/DescriptionTwoTone';
import ThumbUpAltTwoTone from '@mui/icons-material/ThumbUpAltTwoTone';
import CalendarTodayTwoTone from '@mui/icons-material/CalendarTodayTwoTone';
import useAuth from "../../hooks/useAuth.js";
import useTitle from "../../hooks/useTitle.js";
import { useGetUsersQuery } from "../../features/users/usersApiSlice.js";
import Subscribe from './Subscribe.js';
import CountDown from './CountDown.js';
import WhatsappImage from './bin/WhatsappImage.js';

function WhatLayout() {

    useTitle('TOP ANNOTATE: whatsapp dash')


    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 190000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    const [lifeTime, setLifetime] = useState(0);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [instruction, setInstruction] = useState(false);
    const [showEarn, setShowEarn] = useState(false)
    const [working, setWorking] = useState(false)
    const [randPuff, setRandPuff] = useState(0)
    const [activation, setActivation] = useState('');


    const [searchParams] = useSearchParams();
    const referrerUsername = searchParams.get('ref');

    const [isSubscribed, setIsSubscribed] = useState();

    useEffect(() => {

        if (referrerUsername) {
            setWorking(true);
        } else {
            setWorking(false);
        }

    }, [referrerUsername])



    useEffect(() => {
        if (isSuccess) {
            const userDataArray = Object.values(users.entities);
            setLifetime(userDataArray[0].subscription.reduce((sum, task) => sum + task.amount, 0))
            setCompletedTasks((userDataArray[0].subscription))
            setActivation((userDataArray[0].activationStatus ? 'Active' : 'Inactive'))
        }
    }, [isSuccess])

    useEffect(() => {
        setRandPuff(Math.floor(Math.random() * (80 - 20 + 1)) + 30)
    }, [])

    const { username, email } = useAuth()

    const theme = useTheme();


    useEffect(() => {

        const today = new Date().toISOString().slice(0, 10);
        setIsSubscribed(completedTasks.some(entry => entry.date === today))
        console.log(completedTasks.some(entry => entry.date === today), today, completedTasks)

    }, [completedTasks])


    return (
        <div className="dashcon">

            {isSubscribed ? '' : <><div className="wel-ttl">
                <h1>
                    Welcome to TOP ANNOTATE <span> POSTS </span>
                </h1>
            </div>
                <div className="welc-ban">
                    <div className="img-con">
                        <img src={restImg} />
                    </div>
                    <div className="text-con">
                        <div className="text-one">
                            <p>Welcome <span>{username}</span>!</p>
                        </div>
                        <div className="text-2">
                            <p>
                                This is TOP ANNOTATE marketing program.
                            </p>
                        </div>
                    </div>
                </div>

            </>
            }
            <div className='task-tab'>
                <div className='intro-task'>
                    <p className='main-intro'>Complete the Post and Earn</p>
                    <p onClick={() => {
                        setShowEarn(prev => !prev)
                    }} className='toggle'>View Earnings</p>
                </div>
                <div className={`${showEarn ? '' : 'hidden'}`}>
                    <Grid container spacing={gridSpacing} className="dashhere">
                        <Grid item xs={12} className="fst-items">
                            <Grid container spacing={gridSpacing}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ReportCard
                                        primary={lifeTime}
                                        secondary="Post Earnings"
                                        color={theme.palette.warning.main}
                                        footerData="100% Pending review"
                                        iconPrimary={MonetizationOnTwoTone}
                                        iconFooter={TrendingUpIcon}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ReportCard
                                        primary={completedTasks.length}
                                        secondary="Subscribed posts"
                                        color={theme.palette.error.main}
                                        footerData={`${randPuff}% task performance`}
                                        iconPrimary={CalendarTodayTwoTone}
                                        iconFooter={TrendingDownIcon}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ReportCard
                                        primary="0"
                                        secondary="Processed Balance"
                                        color={theme.palette.success.main}
                                        footerData="100% approved task"
                                        iconPrimary={DescriptionTwoTone}
                                        iconFooter={TrendingUpIcon}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ReportCard
                                        primary={activation}
                                        secondary="status"
                                        color={theme.palette.primary.main}
                                        footerData="Status To process tasks"
                                        iconPrimary={ThumbUpAltTwoTone}
                                        iconFooter={TrendingUpIcon}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='instructions'>
                <div className='inst-tt'>
                    <p onClick={() => {
                        setInstruction(prev => !prev)
                    }}>
                        Instructions
                    </p>
                    <p style={{ background: '#adadad', marginRight: '15px' }}>Time to Reset: <CountDown /></p>
                </div>
                <div className={`inst-pp ${instruction ? '' : 'hidden'}`}>
                    <p>
                        The number of views each reflect on your dashboard , Ensure you upload a proof before 24hrs
                    </p>
                    <p>
                        Avoid mistakes ! Submitting old ads will automatically not be approved.
                    </p>
                    <p>
                        Withdraw your earnings weekly, any earnings which aren't withdrawn will be cleared every week.
                    </p>
                </div>

            </div>
            {isSubscribed ? <WhatsappImage /> : <Subscribe />}

            <div className="tasks-con">
                <h2 className="title-task">Submitted Posts</h2>
                {completedTasks.length > 0 ? (
                    <ul className="item-con">
                        <li className="ttl-item-single"> {/* Assign a unique key to each list item */}
                            <p className="single-p"><span>Date</span></p>
                            <p className="single-p"><span>Ref ID</span></p>
                            <p className="single-p"><span>pay</span></p>
                            <p className="single-p"><span>number</span></p>
                            <p className="single-p"><span>Status</span></p>
                        </li>
                        {/* Use a list to display multiple tasks */}
                        {completedTasks.map((task, index) => (
                            <li key={index} className="item-single"> {/* Assign a unique key to each list item */}
                                <p className="single-p"> {task.date}</p>
                                <p className="single-p"> {task._id.slice(0, 6)}</p>
                                <p className="single-p"> {task.amount ? task.amount : 'incomplete'}</p>
                                <p className="single-p"> {task.phoneNumber ? task.phoneNumber : 'incomplete'}</p>
                                <p className={`single-p ${task.status === 'in review' ? "pend" : task.status === 'rejected' ? 'rejected' : ''}`}>{task.status}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No completed tasks yet.</p>
                )}
            </div>

            <Outlet />
        </div>
    );
}

export default WhatLayout;
