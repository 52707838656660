import React, { useEffect } from "react";
import { FaAnglesLeft } from "react-icons/fa6";
import itemLogo from "../bin/pngegg.png";
import { TbDotsVertical } from "react-icons/tb";
import { LuAlignLeft } from "react-icons/lu";
import { HiOutlineHome } from "react-icons/hi";
import { RiEnglishInput } from "react-icons/ri";
import { FiBell } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { MdOutlineManageAccounts } from "react-icons/md";
import { FaPowerOff } from "react-icons/fa6";
import { useSendLogoutMutation } from '../auth/accessAuth/authAPI/authApiSlice'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from "react-hot-toast";
import useAuth from "../hooks/useAuth";

export default function (props) {

    const navigate = useNavigate()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/login')
    }, [isSuccess, navigate])

    useEffect(() => {
        if (isError) {
            toast.error('Error: ' + error.data.message)
        }
    }, [isError])

    const {username, email} = useAuth()



    return (
        <div className="nan-main">
            <Toaster
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                }}
            />

            <div className="mob-full">
                <div onClick={() => {
                    props.mobileScreen();
                }} className="burger">
                    <LuAlignLeft style={{ width: '30px', height: '30px', color: '#edeef3' }} />
                </div>
                <div className="logoHere" onClick={() => {
                    props.semiScreen();
                }}>
                    < img style={{ width: '40px', height: '40px' }} src={itemLogo} />
                    <h3>TOP ANNOTATE</h3>
                </div>
                <div
                    onClick={() => {
                        props.semiScreen();
                    }}
                    className="dot"
                >
                    <FaAnglesLeft style={{ width: '25px', height: '25px', color: '#edeef3' }} />
                </div>
                <div onClick={() => {
                    props.mobilebarf();
                }} className="three-dots">
                    <TbDotsVertical style={{ width: '25px', height: '25px', color: '#edeef3' }} />
                </div>
            </div>
            <div className="mob-hid">
                <div className="right-group">
                    <HiOutlineHome style={{ width: '25px', height: '25px', color: '#2c343b' }} />
                </div>

                <div className="left-group">
                    <div>
                        <RiEnglishInput style={{ width: '22px', height: '22px', color: '#2c343b' }} />
                    </div>
                    <div>
                        <FiBell style={{ width: '22px', height: '22px', color: '#2c343b' }} />
                    </div>
                    <div>
                        <FiMail style={{ width: '22px', height: '22px', color: '#2c343b' }} />
                    </div>
                    <h3>{email.slice(0, 5)}...</h3>
                    <div className="con-log" onClick={() => { props.SubMenu() }}>
                        <CgProfile style={{ width: '32px', height: '32px', color: '#2c343b' }} />
                        <div className={`con-log-con ${props.mobileSubMenu ? '' : 'hidesub'}`}>
                            <p>
                                <MdOutlineManageAccounts style={{ width: '19px', height: '19px', color: '#2c343b', position: 'relative', top: '2px' }} />
                                Account
                            </p>
                            <p>
                                <CgProfile style={{ width: '17px', height: '17px', color: '#2c343b', position: 'relative', top: '2px' }} />
                                Profile
                            </p>
                            <hr />
                            <p onClick={sendLogout}>
                                <FaPowerOff style={{ width: '17px', height: '17px', color: '#2c343b', position: 'relative', top: '3px' }} />
                                Log Out
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
