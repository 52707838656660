import React, { useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom'; // Import Outlet
import Header from '../Header';
import Footer from '../Footer';
import Pannel from '../Pannel';

export default function Layout({ children }) {
    const location = useLocation();
    const shouldShowNav = location.pathname !== '/login' && location.pathname !== '/signup';
    const [semiBigNav, setSemiBigNav] = useState(false)
    const [mobileNav, setMobileNav] = useState(false)
    const [mobileBar, setMobileBar] = useState(false)
    const [mobileSubMenu, setMobileSubMenu] = useState(false)

    const SubMenu = ()=> {
        setMobileSubMenu(!mobileSubMenu)
    }


    const semiScreen = ()=> {
        setSemiBigNav(!semiBigNav)
    }

    const mobileScreen = ()=> {
        setMobileNav(!mobileNav)
        if (mobileBar) {
            mobilebarf()
        }

    }

    const mobilebarf = ()=> {
        setMobileBar(!mobileBar);
        if (mobileNav) {
            setMobileNav(!mobileNav)
        }
        if (mobileSubMenu) {
            SubMenu()
        }
    }

    return (
        <div className={`constant ${semiBigNav ? 'small-base' : 'main-base'} ${mobileNav ? 'mobile-active' : 'mobile-notactive'} ${mobileBar ? 'mobile-active-bar' : ''}`}>
            {shouldShowNav && <Header semiScreen={semiScreen} mobileScreen={mobileScreen} mobilebarf={mobilebarf} SubMenu={SubMenu} mobileSubMenu={mobileSubMenu}/>}
            <div className='lay-layout'>
                <div className='nav-main'>
                    <Pannel mobileScreen={mobileScreen}/>
                </div>
                <div className='content-main'>
                <Outlet />
                </div>
            </div>
            {shouldShowNav && <Footer />}
        </div>
    );
}