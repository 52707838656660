import React, { useEffect } from "react";
import { useRegisterPostMutation } from "../../features/users/usersApiSlice";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Subscribe() {

    const navigate = useNavigate()

    const [registerPost, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRegisterPostMutation()

    useEffect(() => {
        if (isError) {
            if (error && error.status === 400) { // Check for 400 Bad Request
                if (error.data.error) {
                    toast.error('Input Error: ' + error.data.error);
                } else {
                    toast.error('Bad Request. Please check your input.'); // Generic 400 message 
                }
            } else if (error && error.status === 409) { // Check for 409 Conflict
                toast.error('Conflict: ' + error.data.error);
            } else {
                console.log(error)
                toast.error('Error: ' + (error.data.message || 'An unknown error occurred')); // Fallback
            }
        }
    }, [isError]);


    useEffect(() => {
        if (isSuccess) {
            toast.success('Subscribed successfully')
            setTimeout(() => {
                navigate('/dash/whatsapp', { replace: true })
                window.location.reload();
            }, 2000);
            setTimeout(() => {
                toast.success('Redirecting...')
            }, 1000);
        }
    }, [isSuccess])

    const handleSubmit = async () => {
        await registerPost()
      }

    return (
        <>
              <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />

        <div className='subs'>
            <p className='sub-one'>You are not subscribed to todays promotion!</p>
            <p className='sub-two' onClick={()=>{
                handleSubmit()
            }}>Subscribe Now</p>
        </div>
        </>
    );
}

export default Subscribe;
