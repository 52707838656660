import { Routes, Route } from 'react-router-dom';
import TestPage from './pages/TestPage';
import Layout from "./navigation/layout/Layout";
import Signup from './auth/SignUp';
import SignIn from './auth/accessAuth/SignIn';
import UserMain from './features/users/UserMain';
import Prefetch from './auth/accessAuth/Prefetch';
import PersistLogin from './auth/accessAuth/PersistLogin';
import DashboardMain from './components/DashboardMain';
import TaskLayout from './components/tasksCenter/TaskLayout';
import TaskList from './components/tasksCenter/TaskList';
import RandomTasker from './components/tasksCenter/RandomTasker';
import RandomTaskImage from './components/tasksCenter/RandomTaskImage';
import CompletedTasks from './components/tasksCenter/CompletedTasks';
import WhatLayout from './components/whatsapp/WhatLayout';
import VerifyEmail from './pages/VerifyEmail';
import Terms from './pages/Terms';
import Wallet from './components/Wallet';
import Activity from './components/Activity';
import Reedem from './components/Reedem';


export default function App() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
      <Route element={<Prefetch />}>
        <Route path="dash" element={<Layout />}> {/* Wrap routes needing layout */}
          <Route index element={ <DashboardMain />} />
          <Route path='user' element={<UserMain />} />
          <Route path='wallet' element={< Wallet />} />
          <Route path='activity' element={<Activity />} />
          <Route path='redeem' element={<Reedem />} />
          <Route path="task" element={<TaskLayout />}>
            <Route path='completed' element={<CompletedTasks />} />
            <Route index element={<TaskList />}/>
            <Route path="image">
              <Route path=":taskId" element={<RandomTaskImage />}/>
            </Route>
            <Route path="video">
              <Route path=":taskId" element={<RandomTasker />}/>
            </Route>
          </Route>
          <Route path="whatsapp" element={<WhatLayout />}>
            <Route index  />
          </Route>
        </Route>
      </Route>
      </Route>
      <Route path="login" element={<SignIn />} />
      <Route path="signup" element={<Signup />} />
      <Route path="verify-email" element={<VerifyEmail />} />
      <Route path="terms" element={<Terms />} />
      <Route path="/" element={<>
        <iframe src="/face/index.html" title="my-template" width="100%" height="1000"></iframe>
      </>} />
    </Routes>
  );
}