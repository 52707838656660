import React, { useState, useEffect } from "react";

function CountDown() {

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    
    useEffect(() => {
        const timerId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000); // Update every second

        return () => clearInterval(timerId); // Clean up on unmount
    }, []);


    
    function calculateTimeRemaining() {
        const now = new Date();
        const tomorrow = new Date(now);
        tomorrow.setHours(24, 0, 0, 0); // Set to midnight

        const difference = tomorrow - now;
        return Math.max(difference, 0); // Ensure time doesn't go negative
    }
    
    function formatTime(ms) {
        const totalSeconds = Math.floor(ms / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    function pad(number) {
        return number.toString().padStart(2, '0');
    }

  return (
    <>
        {formatTime(timeRemaining)}
    </>
  );
}

export default CountDown;
