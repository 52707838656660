import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PulseLoader from 'react-spinners/PulseLoader'
import TaskForm from './TaskForm';

function RandomTaskImage() {
  const [videoData, setVideoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/task/video`) 
      .then(response => {
        setVideoData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return <p>Error loading video: {error}</p>;
  }

  return (
    <>
    <div style={{padding:'10px 0px'}}>
      {isLoading ? (
        <PulseLoader />
      ) : (
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src={videoData.thumbnailUrl} alt="Video Thumbnail" style={{ maxHeight: '600px', maxWidth: '100%'}} />        </div>
      )}
    </div>
    <TaskForm />
    </>
  );
}

export default RandomTaskImage;
